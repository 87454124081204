<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-card-title>Компании</v-card-title>
      <v-container>
        <v-data-table
          :headers="headers"
          :items="filteredCompanies"
          :items-per-page="50"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              color="white"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
              <v-spacer/>
              <router-link :to="{ name: 'CompanyCreate' }">
                <v-btn
                  color="primary"
                  dark
                >
                  Добавить
                </v-btn>
              </router-link>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <router-link :to="{ name: 'CompanyView', params: { id: item.id } }">
                  {{ item.id }}
                </router-link>
              <td>
                <router-link :to="{ name: 'CompanyView', params: { id: item.id } }">
                  {{ item.name }}
                </router-link>
              </td>
              <td>{{ item.city }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-main>
</template>


<script>
import account from "@/api/account";

export default {
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Юр лицо', value: 'name'},
        {text: 'Город', value: 'city'},
      ],
      companies: [],
    };
  },
  computed: {
    filteredCompanies() {
      return this.companies.filter(company => {
        const searchTerm = this.search.toLowerCase();
        return (
          company.name.toLowerCase().includes(searchTerm) ||
          company.address.toLowerCase().includes(searchTerm)
        );
      });
    },
  },
  created() {
    this.fetchCompanies();
  },
  methods: {
    async fetchCompanies() {
      this.loading = true;
      try {
        const response = await account.getCompanyList();
        console.log(response.data);
        this.companies = response.data;
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
